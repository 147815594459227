import '../styles/index.scss';

const form = document.querySelector("#form");
const button = form.querySelector('button');
const inputs = form.querySelectorAll("input, textarea");

form.reset();

const inputStatus = {
    Name: false,
    Phone: false,
    Text: false
};

const checkInputs = () => {
    const {Name, Phone, Text} = inputStatus;
    if (Name & Phone & Text) {
        return false;
    } else {
        return true;
    }
};

for (let input of Array.from(inputs)) {
    if (input.value) {
        inputStatus[input.name] = true;
    }
    input.addEventListener("input", () => {
        if (input.value) {
            inputStatus[input.name] = true;
            button.disabled = checkInputs();
            console.log(checkInputs());
        }
    });
}
button.disabled = checkInputs();

form.addEventListener("submit", async (event) => {
    event.preventDefault();
    const formData = new FormData(form);
    button.innerText = 'Отправка...';
    button.disabled = true;

    console.log(JSON.stringify({
        Name: formData.get("Name"),
        Phone: formData.get("Phone"),
        Text: formData.get("Text")
    }));
    await fetch(`https://script.google.com/macros/s/AKfycbz0X6v_ffFo23sJwhXCYV-Xllx0ruoTmQ1Q26hx0ClSbxAuxkBUChebZpHH6fbGtxAr/exec`, {
        method: 'POST',
        body: new URLSearchParams(formData)
    });
    form.innerHTML = '<h3>Ваша заявка отправлена! Скоро мы с вами свяжемся</h3>';
    
});

document
    .querySelectorAll('.services__card')
    .forEach(el => el.addEventListener('click', () => {
        console.log(el.dataset.type);
        document.querySelector('#contact-form').scrollIntoView();
        form.elements.Type.value = el.dataset.type;
    }));